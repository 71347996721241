import { acceptHMRUpdate, defineStore } from 'pinia';
import { findDownloadIdInSlug } from './helper/findDownloadIdInSlug';
import { isDefined } from '~/utils/guards/isDefined';
import type { LocationQuery, RouteParams } from 'vue-router';
import type {
  SoftwareEntryModel,
  SoftwareResponseModel
} from '~/lib/DownloadService/model';
import type { SoftwareQueryModel } from '~/server/api/downloads/software/index.get';

export type DownloadStoreState = {
  query: string;
  staticFilters: Record<string, string[]>;
  filters: Record<string, string[]>;
  loading: boolean | undefined;
  activeModal: string | undefined | boolean;
  software: SoftwareEntryModel | undefined;
  softwareDownloads: SoftwareResponseModel | null;
  offset: number;
};

// The name of the filter which must be set to enable selection of other filters
const SOFTWARE_PRODUCT_FILTER_NAME = 'downloadCategory.values.label.data';

const CATEGORY_ANALYTICS_MAP: Record<string, string> = {
  [SOFTWARE_PRODUCT_FILTER_NAME]: 'software-type_filter_selection',
  'softwareVersion.data': 'software-version_filter_selection',
  'groupAssets.supportedOs.data': 'supported-os_filter_selection'
};

const DOWNLOADS_PER_PAGE = 6;

export const useDownloadStore = defineStore('softwareDownloads', {
  state: (): DownloadStoreState => ({
    query: '',
    staticFilters: {},
    filters: {},
    loading: undefined,
    activeModal: false,
    software: undefined,
    softwareDownloads: null,
    offset: 0
  }),
  getters: {
    mergedFilters: (state) => ({
      ...state.filters,
      ...state.staticFilters
    }),
    hasActiveFilters: (state): boolean =>
      Object.values(state.filters).some((values) => !isEmpty(values)),
    haveRequiredFiltersValues(): boolean {
      // the required filter must have a value...
      return (
        !isEmpty(this.filters[SOFTWARE_PRODUCT_FILTER_NAME]) ||
        // or any other filter (might be set via url params or manually after
        // selecting the required parameter
        this.hasActiveFilters
      );
    },
    /**
     * A map which contains a filter name (key) and a enabled state (value)
     * for that filter.
     * Filters are only enabled if the necessary filter has a value or
     * any other filter has a value (e.g. via url parameters or manually
     * set after selecting the necessary filter)
     * "Software Product" filter must be enabled.
     */
    filterDisabledList(): Record<string, boolean> {
      return Object.fromEntries(
        this.softwareDownloads?.filters.map((facet) => [
          facet.name,
          facet.name !== SOFTWARE_PRODUCT_FILTER_NAME &&
            !this.haveRequiredFiltersValues
        ]) ?? []
      );
    },
    /**
     * Flag if the initial view is present or not.
     */
    isInitialView(): boolean {
      return (
        !this.hasActiveFilters &&
        this.softwareDownloads != null &&
        this.softwareDownloads.results.length !== 0 &&
        this.softwareDownloads.query?.length === 0
      );
    },
    canLoadMore(): boolean {
      return (
        (this.softwareDownloads &&
          this.softwareDownloads?.filters.length !== 0 &&
          this.softwareDownloads?.results.length <
            this.softwareDownloads?.maxCount) ??
        false
      );
    }
  },
  actions: {
    async updateFromRouteAndFetch(routeQuery: LocationQuery): Promise<void> {
      const routeFilters: Record<string, string[]> = {};

      for (const key in routeQuery) {
        const value = routeQuery[key];
        routeFilters[key] = ensureArray(value)
          .map((value) => value?.toString())
          .filter(isDefined);
      }
      this.filters = routeFilters;
      await this.fetchSoftwareDownloads();
    },
    async handleRouteChange(params: RouteParams) {
      const { $globalPageSettings } = useNuxtApp();
      const softwareCenterSlug =
        $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;
      if (!softwareCenterSlug) {
        return;
      }
      const downloadId = findDownloadIdInSlug(
        ensureArray(params.slug),
        softwareCenterSlug
      );

      if (downloadId) {
        await this.openModal(downloadId);
      } else {
        this.activeModal = undefined;
      }
    },
    async openModal(downloadId: string): Promise<void> {
      const { $analytics } = useNuxtApp();
      if (this.softwareDownloads?.results) {
        this.softwareDownloads?.results.filter((softwareItem) => {
          if (downloadId === softwareItem.id) {
            this.software = softwareItem;
            return;
          }
        });
      }

      if (!this.software) {
        await this.fetchDownloadById(downloadId);
      }

      this.activeModal = downloadId;

      $analytics?.pushToDataLayer({
        action: 'show_download',
        category: 'downloads',
        event: 'click_Internal',
        label: this.software?.name
      });
    },
    async fetchSoftwareDownloads(): Promise<void> {
      const { $resolvedLocale } = useNuxtApp();
      this.loading = true;
      this.offset = 0;

      // XXX: abort controller
      // XXX: error handling
      this.softwareDownloads = await $fetch<SoftwareResponseModel>(
        '/api/downloads/software',
        {
          method: 'get',
          query: {
            query: this.query,
            locale: $resolvedLocale.value!,
            filters: JSON.stringify(this.mergedFilters),
            limit: DOWNLOADS_PER_PAGE.toString()
          } satisfies SoftwareQueryModel
        }
      );

      this.loading = false;
    },
    async fetchMoreSoftwareDownloads(): Promise<void> {
      const { $resolvedLocale } = useNuxtApp();

      this.offset = this.softwareDownloads?.results?.length ?? 0;

      // XXX: abort controller
      // XXX: error handling
      const moreDownloads = await $fetch<SoftwareResponseModel>(
        '/api/downloads/software',
        {
          method: 'get',
          query: {
            query: this.query,
            locale: $resolvedLocale.value!,
            filters: JSON.stringify(this.mergedFilters),
            limit: DOWNLOADS_PER_PAGE.toString(),
            offset: this.offset.toString()
          } satisfies SoftwareQueryModel
        }
      );
      if (this.softwareDownloads && this.softwareDownloads.results) {
        this.softwareDownloads.results = this.softwareDownloads.results.concat(
          moreDownloads.results
        );
      }
    },

    async fetchDownloadById(id: string): Promise<void> {
      const { $resolvedLocale } = useNuxtApp();
      const logger = useLogger();

      try {
        this.software = await $fetch<SoftwareEntryModel>(
          '/api/downloads/software/byId',
          {
            method: 'get',
            query: {
              id,
              locale: $resolvedLocale.value
            }
          }
        );
      } catch (e) {
        logger.error(`could not load software download by id "${id}"`, e);
      }
    },

    handleFormValueChange(key: string, value: string | string[]) {
      const { $analytics } = useNuxtApp();

      this.filters = {
        ...this.filters,
        [key]: ensureArray(value)
      };

      if (CATEGORY_ANALYTICS_MAP[key]) {
        $analytics?.pushToDataLayer({
          action: CATEGORY_ANALYTICS_MAP[key],
          category: 'downloads_filter_selection',
          event: 'click_Internal',
          label: value
        });
      }

      this.updateRoute(this.filters);
    },
    updateRoute(filters: Record<string, string[]>) {
      const router = useRouter();

      router.push(this.createUrlParams(filters));
    },
    createUrlParams(filters?: Record<string, string[]>) {
      const clonedFilters = this._cloneFilters(filters ?? this.filters);

      return {
        query: clonedFilters
      };
    },
    _cloneFilters(
      filters?: Record<string, string[]>
    ): Record<string, string[]> {
      return JSON.parse(JSON.stringify(filters ?? this.filters));
    },
    resetFilters() {
      this.filters = {};
      this.updateRoute(this.filters);
    },
    createNewUrlParamsFromFilter(key: string, valueToRemove: string) {
      const clonedFilters = this._cloneFilters();
      const values = this.filters[key];
      const arrayValues = ensureArray(values).filter(isDefined).map(String);

      clonedFilters[key] = arrayValues.filter(
        (value) => value && !valueToRemove.includes(value)
      );

      return this.createUrlParams(clonedFilters);
    },
    closeModal() {
      this.activeModal = false;
      const logger = useLogger();
      const router = useRouter();

      const locale = useLocale();
      const { $globalPageSettings } = useNuxtApp();
      const path =
        $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;

      if (!path) {
        logger.error('no document center page defined - can not close modal');

        return;
      }

      setTimeout(
        () =>
          router.replace({
            path: buildUrlString(locale.value, path),
            query: this.filters
          }),
        300
      );
    },
    afterCloseModal(): void {
      const logger = useLogger();
      const router = useRouter();

      const locale = useLocale();
      const { $globalPageSettings } = useNuxtApp();
      const path =
        $globalPageSettings.value?.softwareCenterPage?.metadata?.slug;

      if (!path) {
        logger.error('no software center page defined - can not close modal');

        return;
      }

      setTimeout(
        () =>
          router.replace({
            path: buildUrlString(locale.value, path),
            query: this.filters
          }),
        300
      );
    },
    changeRoute() {
      this.activeModal = false;
      const router = useRouter();
      const locale = useLocale();
      const { $globalPageSettings } = useNuxtApp();
      const path =
        $globalPageSettings.value?.softwareCenterPage?.metadata?.slug ??
        undefined;

      router.replace({
        path: buildUrlString(locale.value, path),
        query: this.filters
      });
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDownloadStore, import.meta.hot));
}
